(function ($) {
    "use strict";
    let ajaxWork = false;

    checkSearchQuery();

    function checkSearchQuery() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if (typeof params.q !== "undefined") {
            $("#searchToolsForm input[type=text]").val(params.q);
            searchTools();
        }
    }

    $("body").on("click", "#searchTools", function (e) {
        e.preventDefault();
        searchTools();
    });

    function searchTools() {
        let searchStr = $("#searchToolsForm").find("input[type=text]").val();

        var url = new URL(window.location);
        (url.searchParams.has('q') ? url.searchParams.set('q', searchStr) : url.searchParams.append('q', searchStr));

        url.search = url.searchParams;
        url = url.toString();

        // if you want to append into URL without reloading the page
        history.pushState({}, null, url);

        if ($(".tools_area").find(".filter-container").length > 0) {
            if (ajaxWork) {
                return;
            }
            ajaxWork = true;

            let searchString = searchStr;

            $.ajax({
                beforeSend: function (request) {
                    request.setRequestHeader("X-CSRF-TOKEN", $('meta[name="csrf-token"]').attr('content'));
                },
                type      : "POST",
                url       : window.ajaxUrls.srchTools,
                dataType  : 'json',
                data      : {
                    'search_terms'  : searchString,
                    'is_home_search': true,
                },
                success   : function (data) {
                    ajaxWork = false;
                    if (data.status) {
                        $(".filter__category[data-tools-block-type=all]").click();
                        $(".row.catalog-box.tools-block-results[data-tools-block-type=all]").html(data.homeHtml);
                    }
                },
                error     : function (data) {
                    ajaxWork = false;
                    let errors = $.parseJSON(data.responseText);
                    if (typeof errors.message !== "undefined" && errors.message != "") {
                        console.log(errors.message);
                    }
                }
            })
        } else {
            $(".element-tools").each(function (i, elem) {
                let str = $(elem).data('title') + ' ' + $(elem).data('description') + ' ' + $(elem).data('search-terms');
                if (str.toLowerCase().indexOf(searchStr.toLowerCase()) >= 0) {
                    $(elem).show();
                } else {
                    $(elem).hide();
                }
            });
            $(".element-tools:visible").each(function (i, elem) {
                if (i < 8) {
                    $(elem).removeClass('col-lg-2').addClass('col-lg-3');
                    $(elem).find('.tools_box').removeClass('tools_box__small');
                    $(elem).find('p').removeClass('d-none');
                } else {
                    $(elem).removeClass('col-lg-3').addClass('col-lg-2');
                    $(elem).find('.tools_box').addClass('tools_box__small');
                    $(elem).find('p').addClass('d-none');
                }
            });
        }



        window.scrollTo({top: $(".tools_area").position().top, behavior: "smooth"});
    }

})(jQuery);
